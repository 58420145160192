import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { AllBets } from './AllBets';
import { MyBetsContainer } from './MyBets';
import { TopBetsContainer } from './TopWins';

export default function Betlog() {
    const { t } = useTranslation();

    return (
        <Tab.Group defaultIndex={0}>
            <Tab.List className="flex flex-row overflow-hidden flex-grow-1 flex-shrink-0 m-2 justify-center rounded-full bg-zinc-800 ">
                <Tab
                    key={0}
                    as="button"
                    className="flex-1 outline-none ui-selected:bg-white ui-selected:text-stone-900"
                >
                    {t('betlog.allBets')}
                </Tab>
                {/* <Tab
                    key={1}
                    as="button"
                    className="flex-1 outline-none ui-selected:bg-white ui-selected:text-stone-900"
                >
                    Prevboard
                </Tab> */}
                <Tab
                    key={2}
                    as="button"
                    className="flex-1 outline-none ui-selected:bg-white ui-selected:text-stone-900"
                >
                    {t('betlog.myBets')}
                </Tab>
                <Tab
                    key={3}
                    as="button"
                    className="flex-1 outline-none ui-selected:bg-white ui-selected:text-stone-900"
                >
                    {t('betlog.topBets')}
                </Tab>
            </Tab.List>
            <Tab.Panels as={Fragment}>
                <Tab.Panel key={0} className="flex flex-col flex-grow overflow-y-hidden">
                    <AllBets />
                </Tab.Panel>
                {/* <Tab.Panel key={1} className="flex flex-col flex-grow overflow-y-hidden">
                    <PrevRoundBets />
                </Tab.Panel> */}
                <Tab.Panel key={2} className="flex flex-col flex-grow overflow-y-hidden">
                    <MyBetsContainer />
                </Tab.Panel>
                <Tab.Panel key={3} className="flex flex-col flex-grow overflow-y-hidden">
                    <TopBetsContainer />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}
