import { Dialog, Transition } from '@headlessui/react';
import { Fragment, createContext } from 'react';

interface ModalProps {
    children: React.ReactElement<{}>;
    state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const DialogContext = createContext<{
    open?: () => void;
    close?: () => void;
}>({});

export const DialogComponent: React.FC<ModalProps> = function MyModal({ state, children }) {
    const [isOpen, setIsOpen] = state;
    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    return (
        <DialogContext.Provider value={{ open: openModal, close: closeModal }}>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        {/* BACKDROP */}
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        <div className="flex min-h-full align-bottom justify-center text-center sm:items-center items-end sm:p-4 p-2">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="relative w-full max-w-screen-sm transform overflow-hidden rounded-2xl dialog-panel text-left align-middle transition-all ">
                                    <button
                                        onClick={closeModal}
                                        className="absolute flex w-10 h-10 items-center outline-none justify-center right-0 top-0"
                                    >
                                        X
                                    </button>
                                    {children}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </DialogContext.Provider>
    );
};
