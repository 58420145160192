import { Container, Sprite, Texture, Ticker } from 'pixi.js';
import { Eventify } from '../../../Shared/Eventify';
import { config } from '../config';
import { hexToCssColor, interpolateHexColorsWithStrength, valueToScaleClamped } from '../utils/utils';
import Visualisation from './Visualisation';

export default class Background extends Eventify {
    container: Container = new Container();

    gradientSprite: Sprite | undefined;
    gradientCtx: CanvasRenderingContext2D | undefined;
    gradientTexture: Texture | undefined;
    gradientCanvas: HTMLCanvasElement = document.createElement('canvas');
    colors = Object.entries(config.multiplierColors)
        .map((v) => v.map(Number))
        .sort((a, b) => a[0] - b[0]) as Array<[number, number]>;

    constructor(public stage: Visualisation) {
        super();

        this.gradientOverlayDraw();
        this.container.addChild(this.gradientSprite!);

        this.listenTo(stage, 'resize', this.resize);
        this.listenTo(this.stage, Visualisation.Events.tick, () => {
            this.tick();
        });
    }
    resize = () => {
        Ticker.shared.addOnce(() => {
            this.gradientResize();
        });
    };
    tick() {
        this.gradientOverlayColorUpdate();
    }

    gradientOverlayColorUpdate() {
        const height = this.stage.size.height;
        const gradient = this.gradientCtx!.createLinearGradient(0, 0, 0, height);

        const factor = valueToScaleClamped(this.stage.app.realtimeState.multiplier, 1, 1000);
        const currentColor = interpolateHexColorsWithStrength(this.colors, factor);

        gradient.addColorStop(0.3, `rgba(0, 0, 0, 1%)`);
        gradient.addColorStop(1, hexToCssColor(currentColor));
        // gradient.addColorStop(0, `rgba(255, 255, 255, 100%)`);

        this.gradientCtx!.fillStyle = gradient;

        this.gradientCtx!.fillRect(0, 0, this.stage.size.width, height);

        if (this.gradientTexture) this.gradientTexture.update();
    }

    gradientResize() {
        const width = this.stage.size.width;
        const height = this.stage.size.height;

        this.gradientCanvas.width = width;
        this.gradientCanvas.height = height;

        const h = (height / 4) * 3;

        this.gradientSprite!.width = width;
        this.gradientSprite!.height = h;
        this.gradientSprite!.position.set(0, height - h);

        this.gradientOverlayColorUpdate();
    }

    gradientOverlayDraw() {
        if (!this.gradientCtx) this.gradientCtx = this.gradientCanvas.getContext('2d')!;

        this.gradientTexture = Texture.from(this.gradientCanvas);
        this.gradientSprite = new Sprite(this.gradientTexture);
        this.gradientSprite.alpha = 0.2;

        this.gradientResize();
    }
}
