import { createRef, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Visualisation from '../Visualisation/Visualisation';
import { appContext } from './contexts';
import { AddPropertiesToWindow } from './utils';

export default function () {
    const { t } = useTranslation();
    const canvasRef = createRef<HTMLCanvasElement>();
    const app = useContext(appContext);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const visualisation = new Visualisation(canvas, app, t);
            AddPropertiesToWindow({ v: visualisation });
        }
    }, []);

    return <canvas ref={canvasRef} />;
}
