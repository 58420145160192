export const AnimationIcon = (props: { className?: string }) => {
    return (
        <>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 2005 2005"
                // style="enable-background:new 0 0 2005 2005;"
                // xml:space="preserve"
                className="w-5 h-5"
                {...props}
            >
                {/**
<style type="text/css"> .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#303034;}
</style>
 */}
                <g className="w-full h-full">
                    <path
                        id="_x31__4_"
                        className="st1"
                        style={{
                            fillRule: 'evenodd',
                            clipRule: 'evenodd',
                            fill: '#ffffff'
                        }}
                        d="M1491.26,1170.85c-3.62,10.27-13.27,16.69-23.58,16.69c-2.76,0-5.57-0.46-8.32-1.43
		c-13.02-4.6-19.85-18.88-15.26-31.91c17.87-50.67,26.52-103.04,26.52-155c0-72.77-16.97-144.69-49.3-209.66
		c-6.15-12.37-1.12-27.38,11.24-33.54c3.58-1.78,7.38-2.63,11.12-2.63c9.19,0,18.03,5.09,22.4,13.87
		c35.76,71.9,54.53,151.4,54.53,231.95C1520.63,1056.71,1511.05,1114.75,1491.26,1170.85z M1381.25,1048.69
		c-0.54,0-1.09-0.02-1.65-0.05c-13.78-0.9-24.22-12.8-23.33-26.58c0.5-7.66,0.74-15.31,0.74-22.96c0-47.72-9.63-94.97-28.11-138.73
		h0c-5.37-12.73,0.58-27.4,13.31-32.77c3.18-1.34,6.47-1.98,9.72-1.98c9.75,0,19.01,5.74,23.04,15.29
		c21.07,49.9,32.05,103.74,32.05,158.19c0,8.72-0.28,17.46-0.85,26.2C1405.32,1038.53,1394.32,1048.69,1381.25,1048.69z
		 M1293.54,798.44c-30.02,9.97-64.87,14.89-98.57,19.65c-9.88,1.4-19.21,2.71-28.41,4.15c-47.46,7.42-81.57,20.73-104.26,40.7
		c-8.71,7.66-15.8,16.46-21.3,26.46c9.27,3.3,17.99,7.74,25.98,13.14c18.23,12.32,32.74,29.65,41.6,50.11
		c38.67,5.37,78.9,19.17,114.1,39.51c55.45,32.03,98,69.27,126.44,110.69c32.23,46.93,45.47,97.8,39.36,151.18
		c-6.26,54.67-31.16,105.88-72.02,148.09c-35.73,36.91-81.09,64.26-127.72,77.01c-18.27,4.99-35.84,7.44-52.28,7.44
		c-39.38,0-72.23-14.03-92.38-40.68c-17.85-23.61-22.52-54.52-14.29-94.51c6.38-30.99,19.54-63.64,32.27-95.22
		c3.73-9.26,7.25-18,10.61-26.69c17.31-44.83,22.84-81.03,16.9-110.67c-2.28-11.38-6.36-21.93-12.27-31.7
		c-8.94,7.62-19.05,13.91-30.05,18.56c-13.88,5.88-29.2,9.13-45.13,9.13c-0.01,0-0.02,0-0.03,0c-0.01,0-0.01,0-0.01,0
		c-4.49,0-8.93-0.26-13.3-0.76c-23.99,30.82-56.06,58.78-91.26,79.11c-55.45,32.03-108.97,50.26-159.05,54.19
		c-6.87,0.54-13.65,0.81-20.35,0.81c-48.55,0-92.28-14.19-130.22-42.3c-44.2-32.75-76.08-79.93-92.19-136.44
		c-14.09-49.41-15.09-102.38-2.81-149.15c16.34-62.21,54.13-102.85,101.11-108.7c3.56-0.44,7.1-0.66,10.65-0.66
		c25.77,0,51.5,11.7,78.31,35.54c23.64,21.02,45.32,48.75,66.3,75.57c6.15,7.86,11.95,15.28,17.8,22.54
		c30.15,37.41,58.73,60.3,87.36,69.98c10.33,3.49,20.81,5.24,31.51,5.24c0.68,0,1.37-0.01,2.05-0.02c-1.25-6.82-1.9-13.84-1.9-20.98
		c0-11.96,1.82-23.58,5.22-34.5c3.4-10.92,8.36-21.14,14.61-30.4c0.98-1.45,1.99-2.88,3.03-4.28
		c-14.69-36.19-22.86-77.95-22.86-118.62c0-64.06,10.97-119.53,32.61-164.88c24.52-51.39,61.93-88.29,111.2-109.69
		c30.68-13.33,63.7-20.04,97.69-20.04c21.9,0,44.2,2.79,66.53,8.39c49.82,12.5,96.18,38.12,130.53,72.14
		c45.69,45.26,61.97,98.32,43.55,141.94C1356.69,766.07,1332.27,785.58,1293.54,798.44z M1083.98,1415.71
		c10.41,13.77,28.86,20.92,52.17,20.92c12,0,25.29-1.9,39.42-5.76c51.67-14.12,106.6-52.86,138.1-107.42
		c13.01-22.54,22.02-47.78,25.15-75.11c11.65-101.84-70.31-171.97-141.12-212.86c-24.85-14.35-52.52-24.83-79.74-30.5
		c-0.52,9.77-2.25,19.25-5.05,28.27c-1.22,3.94-2.65,7.79-4.27,11.54c15.19,18.94,25.24,40.38,30.01,64.18
		c7.87,39.25,1.56,84.57-19.28,138.53c-3.49,9.03-7.25,18.36-10.88,27.37C1087.11,1327.82,1062.91,1387.84,1083.98,1415.71z
		 M947.37,1035.64c7.12,10.55,17.25,18.95,29.06,23.95c7.88,3.33,16.5,5.17,25.68,5.18c6.89,0,13.45-1.04,19.63-2.96
		c6.18-1.92,11.97-4.73,17.25-8.3c10.55-7.12,18.95-17.25,23.94-29.07c3.33-7.88,5.17-16.5,5.18-25.69c0-6.89-1.04-13.46-2.96-19.64
		c-1.92-6.18-4.73-11.98-8.29-17.25c-7.12-10.55-17.25-18.95-29.06-23.95c-7.88-3.33-16.5-5.17-25.68-5.18
		c-6.89,0-13.45,1.04-19.63,2.96c-6.18,1.92-11.98,4.73-17.25,8.3c-10.55,7.12-18.95,17.25-23.94,29.07
		c-3.33,7.88-5.17,16.5-5.18,25.69c0,6.89,1.04,13.46,2.96,19.64C941,1024.57,943.81,1030.36,947.37,1035.64z M909.02,1067.99
		c-7.75,1.2-15.46,1.79-23.13,1.79c-16.05,0-31.89-2.63-47.46-7.89c-37.91-12.81-73.99-40.93-110.29-85.97
		c-6.08-7.54-12.27-15.46-18.26-23.11c-33.77-43.18-71.86-91.88-105.52-91.88c-1.4,0-2.79,0.08-4.17,0.26
		c-25.94,3.23-47.96,30.06-58.92,71.76c-13.61,51.83-7.54,118.78,23.95,173.35c13.01,22.54,30.36,42.97,52.45,59.34
		c31.7,23.49,66.25,32.61,100.83,32.61c55.22,0,110.47-23.28,154.02-48.43c24.85-14.35,47.75-33.08,66.27-53.83
		c-0.52-0.34-1.04-0.69-1.56-1.03C926.37,1087.61,916.83,1078.49,909.02,1067.99z M1289.48,632.41
		c-38.06-37.7-99.06-65.92-162.05-65.92c-26.02,0-52.38,4.82-77.6,15.77c-93.99,40.82-113.72,146.89-113.72,228.69
		c0,28.7,4.76,57.9,13.47,84.32c2.41-1.23,4.87-2.37,7.37-3.43c9.95-4.21,20.63-7.08,31.75-8.36c8.8-22.64,22.34-42.06,40.57-58.1
		c30.05-26.44,72.43-43.63,129.57-52.56c9.57-1.49,19.52-2.9,29.14-4.26c56.52-7.98,120.58-17.03,134.17-49.22
		C1332.32,695.25,1320.11,662.75,1289.48,632.41z M823.06,635.41c3.56-1.76,7.33-2.59,11.05-2.59c9.22,0,18.08,5.12,22.44,13.94
		c6.11,12.39,1.03,27.38-11.35,33.5c-49.81,24.6-93.06,60.59-126.29,104.55c-4.92,6.5-12.39,9.92-19.96,9.92
		c-5.25,0-10.55-1.65-15.06-5.07c-11.01-8.33-13.19-24.02-4.86-35.04C716.93,704.52,766.2,663.5,823.06,635.41z M915.69,538.1
		c-63.38,11.82-122.24,36.4-173.9,71c-51.65,34.6-96.06,79.23-130.41,131.06c-4.81,7.26-12.76,11.19-20.86,11.19
		c-4.74,0-9.54-1.35-13.79-4.17c-11.51-7.63-14.65-23.15-7.02-34.67c38.02-57.36,87.11-106.7,144.26-144.98
		c57.15-38.29,122.38-65.53,192.56-78.61c1.55-0.29,3.09-0.43,4.61-0.43c11.81,0,22.31,8.4,24.55,20.43
		C938.22,522.51,929.27,535.57,915.69,538.1z M955.44,1400.88c-1.03,0-2.07-0.06-3.12-0.19c-62.34-7.77-122.49-29.95-175.24-65.16
		c-11.49-7.67-14.58-23.2-6.92-34.69c4.82-7.22,12.74-11.13,20.82-11.13c4.77,0,9.59,1.36,13.86,4.21
		c46.2,30.85,98.99,50.32,153.66,57.13c13.7,1.71,23.43,14.21,21.72,27.91C978.64,1391.62,967.87,1400.88,955.44,1400.88z
		 M611.27,1301.19c4.71-4.04,10.49-6.01,16.25-6.01c7.05,0,14.06,2.97,19.01,8.75c41.92,48.99,92.63,87.7,148.42,115.15
		c55.78,27.44,116.62,43.6,178.67,47.44c13.78,0.85,24.26,12.72,23.41,26.51c-0.82,13.26-11.83,23.47-24.93,23.47
		c-0.52,0-1.04-0.02-1.57-0.05c-68.67-4.25-135.92-22.12-197.65-52.48c-61.72-30.36-117.92-73.25-164.34-127.51
		C599.55,1325.96,600.78,1310.17,611.27,1301.19z"
                    />
                </g>
            </svg>
        </>
    );
};
