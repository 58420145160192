import { Tab } from '@headlessui/react';
import { Fragment, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'react-scrollbars-custom';
import { ByArgument, PeriodArgument } from '../../../../Shared/MessageTypes';
import { getMultipliersLeaderboard, getWinsLeaderboard } from '../../../../Shared/services/queries';
import ModalRoundInfo from '../Modals/ModalRoundInfo';
import { appContext } from '../contexts';
import Avatar from '../other/Avatar';
import { DialogComponent } from '../other/Dialog';
import { MultiplierBadge } from '../other/MultiplierBadge';
import Username from '../other/Username';

export function TopBetsContainer() {
    const { t } = useTranslation();

    return (
        <Tab.Group defaultIndex={0}>
            <Tab.List className="flex flex-row overflow-hidden flex-grow-1 flex-shrink-0 m-2 justify-center rounded-full bg-zinc-800 ">
                <Tab
                    key={0}
                    as="button"
                    className="flex-1 outline-none data-[headlessui-state=selected]:bg-white data-[headlessui-state=selected]:text-stone-900"
                >
                    {t('sidebar.wins')}
                </Tab>
                <Tab
                    key={1}
                    as="button"
                    className="flex-1 outline-none data-[headlessui-state=selected]:bg-white data-[headlessui-state=selected]:text-stone-900"
                >
                    {t('sidebar.multipliers')}
                </Tab>
            </Tab.List>
            <Tab.Panels as={Fragment}>
                <Tab.Panel key={0} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopTabs by={ByArgument.wins} />
                </Tab.Panel>
                <Tab.Panel key={1} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopTabs by={ByArgument.multipliers} />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}

function BetTopTabs({ by }: { by: ByArgument }) {
    const { t } = useTranslation();

    return (
        <Tab.Group defaultIndex={0}>
            <Tab.List className="flex flex-row overflow-hidden flex-grow-1 flex-shrink-0 m-2 justify-center rounded-full bg-zinc-800 ">
                <Tab
                    key={0}
                    as="button"
                    className="flex-1 outline-none data-[headlessui-state=selected]:bg-white data-[headlessui-state=selected]:text-stone-900"
                >
                    {t('sidebar.day')}
                </Tab>
                <Tab
                    key={1}
                    as="button"
                    className="flex-1 outline-none data-[headlessui-state=selected]:bg-white data-[headlessui-state=selected]:text-stone-900"
                >
                    {t('sidebar.month')}
                </Tab>
                <Tab
                    key={2}
                    as="button"
                    className="flex-1 outline-none data-[headlessui-state=selected]:bg-white data-[headlessui-state=selected]:text-stone-900"
                >
                    {t('sidebar.year')}
                </Tab>
                <Tab
                    key={3}
                    as="button"
                    className="flex-1 outline-none data-[headlessui-state=selected]:bg-white data-[headlessui-state=selected]:text-stone-900"
                >
                    {t('sidebar.all')}
                </Tab>
            </Tab.List>
            <Tab.Panels as={Fragment}>
                <Tab.Panel key={0} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.day} by={by} />
                </Tab.Panel>
                <Tab.Panel key={1} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.month} by={by} />
                </Tab.Panel>
                <Tab.Panel key={2} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.year} by={by} />
                </Tab.Panel>
                <Tab.Panel key={3} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.all} by={by} />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}

function BetTopEntries({ period, by }: { period: PeriodArgument; by: ByArgument }) {
    // TODO add revalidation if visible with given interval
    let bets: any[] = [];

    switch (by) {
        case ByArgument.wins:
            ({ bets } = getWinsLeaderboard(period));
            break;
        case ByArgument.multipliers:
            ({ bets } = getMultipliersLeaderboard(period));
            break;
    }

    const transformedBets = useMemo(
        () =>
            (bets || []).map((b, i) => {
                const date = new Date(b.created);

                return {
                    ...b,
                    username: b.playerId,
                    time: date.getTime(),
                    date: date.getTime(),
                    currency: b.currency,
                    win: b.winFinalAmount,
                    bet: b.finalAmount,
                    id: b.id, // betId
                    roundId: b.gameRoundId,
                    // TODO map these with actual values from BE when introduced
                    isMine: false,
                    img: '',
                    userId: b.playerId
                };
            }),
        [bets]
    );

    return (
        <Scrollbar className="flex flex-row h-full overflow-y-scroll px-1.5">
            <div className="flex flex-col w-full gap-0.5 text-sm">
                {transformedBets.map((betInfo) => {
                    switch (by) {
                        case ByArgument.wins:
                            return <BetTopEntry key={betInfo.id} data={betInfo} />;
                        case ByArgument.multipliers:
                            return <BetMultiplier key={betInfo.id} data={betInfo} />;
                    }
                })}
            </div>
        </Scrollbar>
    );
}

interface IBetTopEntryProps {
    id: string;
    roundId: string;
    userId: string;
    username: string;
    bet: number;
    multiplier: number;
    win: number;
    isMine: boolean;
    currency: string;
    date: number;
    img: string;
    time: number;
}
function BetTopEntry({ data }: { data: IBetTopEntryProps }) {
    const app = useContext(appContext);
    const modalOpened = useState(false);
    return (
        <div className="flex flex-col border border-transparent rounded-md panel-bg-alt">
            <DialogComponent state={modalOpened} key={data.roundId}>
                <ModalRoundInfo initialData={{ id: data.roundId }} />
            </DialogComponent>
            <div className="flex flex-row relative m-1">
                <button className="absolute right-0 top-0" onClick={modalOpened[1].bind(null, true)}>
                    🔰
                </button>
                <div className="flex flex-wrap align-middle justify-center items-center content-center gap-1 w-1/3 flex-0">
                    <Avatar data-avatarid={data.userId} />

                    <div className="flex flex-grow justify-center w-full">
                        <Username name={data.username} data-userid={data.userId} />
                    </div>
                </div>
                <div className="flex flex-col items-center w-2/3 gap-1">
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-1/2">Bet:</div>
                        <div className="flex justify-start w-1/2">
                            {data.currency} {Number(data.bet || 0).toFixed(2)}
                        </div>
                    </div>
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-1/2">Cashed out:</div>
                        <div className="flex justify-start w-1/2">
                            <MultiplierBadge multiplier={data.multiplier} />
                        </div>
                    </div>
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-1/2">Win:</div>
                        <div className="flex justify-start w-1/2">
                            {data.currency} {Number(data.win || 0).toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap w-full rounded-b-md gap-2 p-1 bg-black bg-opacity-50">
                <div className="flex">{new Date(data.date).toDateString()}</div>
                <div className="flex">Round: {data.roundId}</div>
                {/* <button className="flex justify-end flex-1">💬</button> */}
            </div>
        </div>
    );
}

function BetMultiplier({ data }: { data: IBetTopEntryProps }) {
    const modalOpened = useState(false);
    return (
        <div className="flex flex-col border border-transparent rounded-md panel-bg-alt">
            <DialogComponent state={modalOpened} key={data.roundId}>
                <ModalRoundInfo initialData={{ id: data.roundId }} />
            </DialogComponent>
            <div className="flex flex-row relative m-1">
                <button className="absolute right-0 top-0" onClick={modalOpened[1].bind(null, true)}>
                    🔰
                </button>
                <div className="flex flex-wrap align-middle justify-start items-center content-center gap-1 w-1/3 flex-0">
                    <Avatar data-avatarid={data.userId} />
                    <Username name={data.username} data-userid={data.userId} />
                </div>
                <div className="flex flex-col items-center w-2/3">
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-3/5">Multiplier:</div>
                        <div className="flex justify-start w-2/5">
                            <MultiplierBadge multiplier={data.multiplier} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap w-full rounded-b-md gap-2 p-1 bg-black bg-opacity-50">
                <div className="flex">{new Date(data.date).toDateString()}</div>
                {/* <button className="flex justify-end flex-1">💬</button> */}
            </div>
        </div>
    );
}
