import { BitmapText, Container, Graphics } from 'pixi.js';
import { RoundState } from '../../../Shared/Types';
import { calculatePointOnBezierCurve, distance, valueToScaleClamped } from '../utils/utils';
import { Plot } from './Plot';
import Visualisation from './Visualisation';

const LABEL_X_OFFSET = 13;
const DASH_LENGTH = 10;
const GAP_SIZE = 10;

export class Marker extends Container {
    isMobile = false;
    targetMultiplier = 0;
    visible = false;
    targetY = 0;
    tooltipHeight = 18;
    // label: Text;
    label: BitmapText;
    markerGraphics: Graphics;

    constructor(
        public crashEngine: Visualisation,
        public plot: Plot,
        public tooltipFill = 4934748
    ) {
        super();

        this.label = new BitmapText('0.00x', {
            fontName: 'RobotoMarker',
            fontSize: 15,
            tint: 0xffffff
        });

        this.label.x = this.plot.plotWidth + LABEL_X_OFFSET;
        this.label.y = this.height;
        this.label.anchor.set(0, 0);

        this.targetMultiplier = 0;

        // init the line
        this.markerGraphics = new Graphics();

        // create dashed line
        this.draw();

        this.addChild(this.markerGraphics, this.label);
    }

    drawDashedLine() {
        this.markerGraphics.lineStyle(2, this.tooltipFill, 0.8);
        const tooltipHeight_05 = this.tooltipHeight / 2;

        const p1 = { x: 0, y: tooltipHeight_05 };
        const p2 = { x: this.plot.plotWidth, y: tooltipHeight_05 };

        const len = distance(p1, p2);

        const norm = { x: (p2.x - p1.x) / len, y: (p2.y - p1.y) / len };

        this.markerGraphics.moveTo(p1.x, p1.y).lineTo(p1.x + DASH_LENGTH * norm.x, p1.y + DASH_LENGTH * norm.y);
        let progress = DASH_LENGTH + GAP_SIZE;

        while (progress < len) {
            this.markerGraphics.moveTo(p1.x + progress * norm.x, p1.y + progress * norm.y);
            progress += DASH_LENGTH;
            this.markerGraphics.lineTo(p1.x + progress * norm.x, p1.y + progress * norm.y);
            progress += GAP_SIZE;
        }
    }

    draw() {
        // clear for redraw
        this.markerGraphics.cacheAsBitmap = false;
        this.markerGraphics.clear();

        this.drawDashedLine();
        this.drawTooltip();

        this.markerGraphics.cacheAsBitmap = true;
    }

    setTargetMultiplier(t: number) {
        this.targetMultiplier = t;
        this.label.text = `${t.toFixed(2)}x`;
        // this.alpha = 0;
        this.visible = true;
    }

    // TODO uncomment below or change the way the marker y position is calculated
    tick() {
        if (this.visible) {
            if (
                this.targetMultiplier !== null &&
                (this.crashEngine.app.state.roundState === RoundState.ROUND_STARTED ||
                    this.crashEngine.app.state.roundState === RoundState.ROUND_ENDED)
            ) {
                // TODO these could be cached in the future
                const start = this.plot.stage.airplaneScene.airplaneStart;
                const target = this.plot.stage.airplaneScene.graphPos;

                // this seems to be expensive as well
                const cubic = this.plot.stage.airplaneScene.curveForm.map((v, i) =>
                    !(i % 2) ? start.x + v * (target.x - start.x) : start.y + v * (target.y - start.y)
                );

                const scale = valueToScaleClamped(this.targetMultiplier, 1, this.plot.yAxisMultiplier);
                const point = calculatePointOnBezierCurve(scale, start, cubic, target);

                this.y = point[1] - this.tooltipHeight / 2;

                //  const height = this.plot.plotHeight - target.y;
                //  this.y =
                //      this.plot.plotHeight -
                //      (height / this.plot.yAxisMultiplier) * this.targetMultiplier -
                //      this.tooltipHeight / 2;
            }

            // - this.par.plotBounds.y - needs to be redefined elsewhere if we want to make a flag
            // let e = this.plot.getYMultiplier(this.y + this.tooltipHeight / 2 - this.plot.plotBounds.y);
            // if (e < 1) {
            //     e = 1;
            // }
            this.label.text = `${this.targetMultiplier.toFixed(2)}x`;
        }
    }

    drawTooltip(fillAlpha: number = 1, yOffset: number = 0) {
        const plotWidth = this.plot.plotWidth;
        const plotOffsetX = this.plot.plotOffsetX;
        const tooltipHeight = this.tooltipHeight;
        const tooltipWidth = 10;
        const tooltipWidth2 = 10;
        this.markerGraphics.beginFill(this.tooltipFill, fillAlpha);
        this.markerGraphics.moveTo(plotWidth, tooltipHeight / 2 + yOffset);
        this.markerGraphics.lineTo(plotWidth + tooltipWidth, yOffset);
        this.markerGraphics.lineTo(plotWidth + plotOffsetX + tooltipWidth2, yOffset);
        this.markerGraphics.lineTo(plotWidth + plotOffsetX + tooltipWidth2, tooltipHeight + yOffset);
        this.markerGraphics.lineTo(plotWidth + tooltipWidth, tooltipHeight + yOffset);
        this.markerGraphics.closePath();
        this.markerGraphics.endFill();

        this.markerGraphics.beginFill(16777215, 0.08);
        this.markerGraphics.moveTo(plotWidth, tooltipHeight / 2 + yOffset);
        this.markerGraphics.lineTo(plotWidth + tooltipWidth, yOffset);
        this.markerGraphics.lineTo(plotWidth + plotOffsetX + tooltipWidth2, yOffset);
        this.markerGraphics.lineTo(plotWidth + plotOffsetX + tooltipWidth2, tooltipHeight / 2 + yOffset);
        this.markerGraphics.lineTo(plotWidth, tooltipHeight / 2 + yOffset);
        this.markerGraphics.closePath();
        this.markerGraphics.endFill();
    }
    onResize() {
        this.label.x = this.plot.plotWidth + LABEL_X_OFFSET;

        this.draw();
    }
}
