import { Dialog } from '@headlessui/react';
import formatDuration from 'format-duration';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoundInfo } from '../../../../Shared/services/queries';
import { IRoundInfo } from '../../../../Shared/Types';
import Button from '../other/Button';
import { DialogComponent, DialogContext } from '../other/Dialog';
import { MultiplierBadge } from '../other/MultiplierBadge';
import ModalProvablyFair from './ModalProvablyFair';

// TODO prob delete commented lines
// interface ParticipationSeedInfo {
//     seed: string;
//     username: string;
//     userId: string;
// }

// const fakeSeedInfo: ParticipationSeedInfo[] = Array.from({ length: 3 }, () => ({
//     seed: '',
//     username: '',
//     userId: ''
// }));

const ModalRoundInfo: React.FC<{
    initialData: Partial<IRoundInfo> & Pick<IRoundInfo, 'id'>;
}> = ({ initialData = {} }) => {
    // const app = useContext(appContext);
    const { t } = useTranslation();

    const [round, setState] = useState<IRoundInfo>(initialData as IRoundInfo);
    const [loaded, setLoaded] = useState(false);
    // const [participants, setParticipants] = useState<ParticipationSeedInfo[]>(fakeSeedInfo);
    const { close } = useContext(DialogContext);

    const getRoundResult = useCallback(async () => {
        // const roundInfo = await app.network.getRoundInfo(round.id, );
        const roundInfo = await getRoundInfo(round.id);

        if (roundInfo) {
            setLoaded(true);
            setState(roundInfo);

            // const seedInfo: ParticipationSeedInfo[] = roundInfo.participants.map((p: IParticipation) => ({
            //     seed: p.seed,
            //     username: p.userId.toString(),
            //     userId: p.userId
            // }));
            // setParticipants(seedInfo);
        }
    }, []);
    useEffect(() => {
        getRoundResult();
    }, [round.id]);

    const stateWhatIsPF = useState(false);

    return (
        <>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header ">
                <div className="flex flex-nowrap gap-2">
                    <div>
                        {t('modalRound.round')} {round.id}
                    </div>
                    <MultiplierBadge
                        multiplier={round?.multiplier || 0}
                        style={{ visibility: loaded ? 'visible' : 'hidden' }}
                    />
                    <div className="text-base">{!loaded || formatDuration(round.roundEnd - round.roundStart)}</div>
                </div>
            </Dialog.Title>

            <div className="m-4">
                <div className="flex flex-row flex-wrap panel-bg-alt rounded-md my-2 text-sm">
                    <div className="m-2 overflow-hidden select-text w-full">
                        <div className="text-faded whitespace-nowrap">{t('modalRound.serverSeed')}:</div>
                        <input
                            className="bg-transparent outline-none w-full"
                            readOnly
                            value={loaded ? round.serverSeed : '...'}
                        />
                    </div>
                </div>

                {/* {participants.map(({ seed, username, userId }, i) => (
                    <div key={i} className="flex flex-row panel-bg-alt rounded-md my-2 text-sm">
                        <div className="m-2 overflow-hidden flex flex-wrap w-full">
                            <div className="flex w-1/3 gap-2 items-center">
                                <div className="text-faded whitespace-nowrap">{t('modalRound.participant')}:</div>
                                <div className="flex flex-nowrap items-center gap-2">
                                    <Avatar data-avatarid={userId} />
                                    {username}
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div className="text-faded">{t('modalRound.seed')}:</div>
                                <input
                                    className="bg-transparent outline-none w-full"
                                    value={loaded ? seed : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                ))} */}

                <div className="flex flex-row flex-wrap panel-bg-alt rounded-md my-2 text-sm">
                    <div className="flex flex-col m-2 w-full">
                        <div className="text-faded whitespace-nowrap">{t('modalRound.combinedSHA512Hash')}:</div>
                        <input
                            className="bg-transparent outline-none w-full"
                            value={loaded ? round.hash : '...'}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className="flex flex-row flex-nowrap text-sm text-faded">
                    <div className="flex w-1/3 justify-center">{t('modalRound.hex')}:</div>
                    <div className="flex w-1/3 justify-center">{t('modalRound.decimal')}:</div>
                    <div className="flex w-1/3 justify-center">{t('modalRound.result')}:</div>
                </div>
                <div className="flex flex-row flex-nowrap panel-bg-alt rounded-md text-sm">
                    <div className="flex m-2 overflow-hidden gap-2 flex-row flex-nowrap w-full min-h-[40px]">
                        <div className="text-center w-1/3 overflow-hidden select-text">
                            {loaded ? round.hex : '...'}
                        </div>
                        <div className="text-center w-1/3 overflow-hidden select-text">
                            {loaded ? round.decimal : '...'}
                        </div>
                        <div className="text-center w-1/3 overflow-hidden select-text">{round.multiplier}</div>
                    </div>
                </div>
            </div>
            <div className="bottom-bar">
                <Button onClick={stateWhatIsPF[1].bind(null, true)}>{t('modalRound.provablyFairButton')}</Button>
                <Button onClick={close}>{t('modalRound.closeButton')}</Button>
            </div>

            <DialogComponent state={stateWhatIsPF}>
                <ModalProvablyFair />
            </DialogComponent>
        </>
    );
};

// const dateFormatOptions: Intl.DateTimeFormatOptions = {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hour12: false // Использование 24-часового формата
// };
// {
//     new Intl.DateTimeFormat('ua-UA', dateFormatOptions).format(round.roundStart);
// }

export default ModalRoundInfo;
