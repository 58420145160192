const INCREASE_PERCENT = 20;
const MINIMUM_PERCENT_FREE = 10;
const NULL_ELEMENT = null;

export default class ObjectPool<T> {
    #poolArray: Array<T | null> = [];
    #freeElements = 0;
    #freeIndex = 0;
    resetFunction: (obj: T) => T = (obj) => obj;
    constructorFunction: () => T = () => ({}) as T;
    getFunction: (obj: T) => T = (obj) => obj;

    constructor(config: { constructor: () => T; get?: (obj: T) => T; reset: (obj: T) => T; size?: number }) {
        this.constructorFunction = config.constructor;
        this.resetFunction = config.reset;
        if (config.get) this.getFunction = config.get;
        const size = config.size || 3;
        for (let i = 0; i < size; i++) this.createElement();
    }

    createElement() {
        this.#freeElements++;
        this.#poolArray.push(this.resetFunction(this.constructorFunction()));
        return this.#poolArray[this.#poolArray.length - 1];
    }
    increasePoolSize() {
        const increaseSize = Math.ceil((INCREASE_PERCENT * this.#poolArray.length) / 100);
        for (let i = 0; i < increaseSize; i++) {
            this.createElement();
        }
    }
    getElement() {
        if (this.#freeElements / this.#poolArray.length <= MINIMUM_PERCENT_FREE / 100) {
            this.increasePoolSize();
        }

        this.#freeElements--;
        const freeElement = this.#poolArray[this.#freeIndex];
        this.#poolArray[this.#freeIndex++] = NULL_ELEMENT;

        return this.getFunction(freeElement!);
    }
    releaseElement(element: T) {
        this.#freeElements++;
        this.#poolArray[--this.#freeIndex] = element;
        this.resetFunction(element);
    }
    get size() {
        return this.#poolArray.length;
    }
}
