import Dinero from 'dinero.js';
import { ICurrencyConfig } from '../../../Shared/ConfigTypes';

/**
 * The function generates x,y points which are passed to the callback.
 * Each callback passes a new point offset from the previous point .
 * The callback function is called for each object passed in the object_array argument.
 * The developer independently determines the processing of callback arguments.
 * Useful for positioning graphics, arranging columns, rows for given graphic objects
 * @param object_array Array of incoming objects
 * @param elems_per_line Number of points in X
 * @param start_x Starting coordinate X of the first object
 * @param start_y Starting coordinate Y of the first object
 * @param space_x Distance between points X
 * @param space_y Distance between points Y
 * @param anchor_x Anchor point X (0-1)
 * @param anchor_y Anchor point Y (0-1)
 * @param callback Callback called for each object, to set coordinates or other action
 */
export function grid_util<T>(
    object_array: T[] = [],
    elems_per_line: number,
    start_x: number,
    start_y: number,
    space_x: number,
    space_y: number,
    anchor_x = 0,
    anchor_y = 0,
    /**
     * @param value Object from input array
     * @param point_x Calculated X coordinate
     * @param point_y Calculated Y coordinate
     * @param index Index of the current object in the array
     */
    callback: (value: T, point_x: number, point_y: number, index: number) => void
) {
    for (let i = 0, total_elems = object_array.length; total_elems > i; i++) {
        const x = i % elems_per_line;
        const y = (i / elems_per_line) | 0;
        const total_elems_at_line = Math.min(elems_per_line, total_elems - y * elems_per_line);
        const x_offset = x + (elems_per_line - total_elems_at_line) / 2;
        const shift_x = (total_elems_at_line - 1) * space_x * anchor_x;

        const shift_y = (total_elems - 1) * space_y * anchor_y;
        const point_x = start_x - shift_x + space_x * x_offset;
        const point_y = start_y - shift_y + space_y * y;

        callback(object_array[i], point_x, point_y, i);
    }
}

export function pad(num: number, size: number) {
    const s = '000000000' + num;
    return s.substring(s.length - size);
}

export const EasingFunctions = {
    // no easing, no acceleration
    linear: (t: number) => t,
    // accelerating from zero velocity
    easeInQuad: (t: number) => t * t,
    // decelerating to zero velocity
    easeOutQuad: (t: number) => t * (2 - t),
    // acceleration until halfway, then deceleration
    easeInOutQuad: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
    // accelerating from zero velocity
    easeInCubic: (t: number) => t * t * t,
    // decelerating to zero velocity
    easeOutCubic: (t: number) => --t * t * t + 1,
    // acceleration until halfway, then deceleration
    easeInOutCubic: (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
    // accelerating from zero velocity
    easeInQuart: (t: number) => t * t * t * t,
    // decelerating to zero velocity
    easeOutQuart: (t: number) => 1 - --t * t * t * t,
    // acceleration until halfway, then deceleration
    easeInOutQuart: (t: number) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
    // accelerating from zero velocity
    easeInQuint: (t: number) => t * t * t * t * t,
    // decelerating to zero velocity
    easeOutQuint: (t: number) => 1 + --t * t * t * t * t,
    // acceleration until halfway, then deceleration
    easeInOutQuint: (t: number) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t),
    easeInBack(x: number) {
        const c1 = 1.70158;
        const c3 = c1 + 1;
        return c3 * x * x * x - c1 * x * x;
    }
};
export function valueToScale(value: number, min: number, max: number) {
    return (value - min) / (max - min);
}
export function valueToScaleClamped(value: number, min: number, max: number) {
    return clamp(valueToScale(value, min, max), 0, 1);
}
export function scaleToValue(scale: number, min: number, max: number) {
    return scale * (max - min) + min;
}
export function scaleToValueClamped(scale: number, min: number, max: number) {
    return clamp(scaleToValue(scale, min, max), min, max);
}

export function queryparams<T>() {
    const qu = {} as Record<string, string>;
    location.search.replace(/([^?=&]+)(=([^&]*))?/g, ($0, $1: string, $2, $3) => (qu[$1] = decodeURIComponent($3)));
    return qu as T;
}

export function money(
    amount: number,
    currency: Pick<ICurrencyConfig, 'code' | 'precision' | 'fractionDigits' | 'useGrouping'>,
    noStyle = false
) {
    try {
        const dinero = Dinero({ amount, precision: currency.precision });

        amount = dinero.toUnit();
        const maximumFractionDigits = currency.fractionDigits;
        const formatter =
            currency && !noStyle
                ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      useGrouping: currency.useGrouping,
                      currency: currency.code,
                      currencyDisplay: 'code',
                      maximumFractionDigits,
                      minimumFractionDigits: maximumFractionDigits
                  })
                : new Intl.NumberFormat('en-US', {
                      useGrouping: currency.useGrouping,
                      maximumFractionDigits,
                      minimumFractionDigits: maximumFractionDigits
                  });

        return formatter.format(amount);
    } catch (error) {
        console.error('money error', error);
        return '';
    }
}

export function getCurrencySymbol(currencyCode: string, locale = 'en-US') {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formatter.formatToParts(0)!.find((part) => part.type === 'currency')!.value;
}

export function clamp(num: number, min: number, max: number) {
    return num <= min ? min : num >= max ? max : num;
}

export function hexToCssColor(hex: number) {
    return `#${hex.toString(16).padStart(6, '0')}`;
}

export function HSLToInt(h: number, s: number, l: number): number {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    let r = 0;
    let g = 0;
    let b = 0;

    if (0 <= h && h < 60) {
        (r = c), (g = x), (b = 0);
    } else if (60 <= h && h < 120) {
        (r = x), (g = c), (b = 0);
    } else if (120 <= h && h < 180) {
        (r = 0), (g = c), (b = x);
    } else if (180 <= h && h < 240) {
        (r = 0), (g = x), (b = c);
    } else if (240 <= h && h < 300) {
        (r = x), (g = 0), (b = c);
    } else if (300 <= h && h < 360) {
        (r = c), (g = 0), (b = x);
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return (r << 16) + (g << 8) + b;
}

export function interpolateHexColors(hexColors: number[], value: number) {
    const numColors = hexColors.length;
    const colorIndex1 = Math.floor(value * (numColors - 1));
    const colorIndex2 = Math.min(colorIndex1 + 1, numColors - 1);
    const factor = (value * (numColors - 1)) % 1;

    let interpolated = 0;

    for (let i = 3; i--; ) {
        const offset = i << 3;
        const component1 = (hexColors[colorIndex1] >> offset) & 255;
        const component2 = (hexColors[colorIndex2] >> offset) & 255;
        interpolated |= Math.round(component1 + (component2 - component1) * factor) << offset;
    }

    return interpolated;
}

export const interpolateHexColorsWithStrength = (() => {
    type Infos = Array<[strength: number, color: number]>;
    const cache = new Map<Infos, number>();
    return function (infos: Infos, x: number) {
        let data1 = infos[0];
        let data2 = infos[infos.length - 1];

        const cached =
            cache.get(infos) ||
            cache
                .set(
                    infos,
                    infos.reduce((a, c) => Math.max(a, c[0]), -Infinity)
                )
                .get(infos)!;
        x *= cached;
        for (let i = 0, l = infos.length - 1; i < l; i++) {
            if (x >= infos[i][0] && x <= infos[i + 1][0]) {
                data1 = infos[i];
                data2 = infos[i + 1];
                break;
            }
        }

        const factor = (x - data1[0]) / (data2[0] - data1[0]);
        const color1 = data1[1];
        const color2 = data2[1];

        let interpolated = 0;

        for (let i = 3; i >= 0; i--) {
            const offset = i << 3;
            const component1 = (color1 >> offset) & 255;
            const component2 = (color2 >> offset) & 255;
            interpolated |= Math.round(component1 + (component2 - component1) * factor) << offset;
        }

        return interpolated;
    };
})();

// TODO remove if the new one works fine
// export function calculatePointOnBezierCurve(
//     t: number,
//     start: { x: number; y: number },
//     cubic: number[],
//     target: { x: number; y: number }
// ): [number, number] {
//     const x =
//         Math.pow(1 - t, 3) * start.x +
//         3 * Math.pow(1 - t, 2) * t * cubic[0] +
//         3 * (1 - t) * Math.pow(t, 2) * cubic[2] +
//         Math.pow(t, 3) * target.x;
//
//     const y =
//         Math.pow(1 - t, 3) * start.y +
//         3 * Math.pow(1 - t, 2) * t * cubic[1] +
//         3 * (1 - t) * Math.pow(t, 2) * cubic[3] +
//         Math.pow(t, 3) * target.y;
//
//     return [x, y];
// }

// This one should be a bit more efficient
export function calculatePointOnBezierCurve(
    t: number,
    start: { x: number; y: number },
    cubic: number[],
    target: { x: number; y: number }
): [number, number] {
    const oneMinusT = 1 - t;
    const oneMinusTSquare = oneMinusT * oneMinusT;
    const tSquare = t * t;

    const oneMinusTCubed = oneMinusTSquare * oneMinusT;

    const oneMinusTSquare3T = oneMinusTSquare * 3 * t;
    const oneMinusT3TSquare = oneMinusT * 3 * tSquare;

    const tCubed = tSquare * t;

    const x =
        oneMinusTCubed * start.x + oneMinusTSquare3T * cubic[0] + oneMinusT3TSquare * cubic[2] + tCubed * target.x;

    const y =
        oneMinusTCubed * start.y + oneMinusTSquare3T * cubic[1] + oneMinusT3TSquare * cubic[3] + tCubed * target.y;

    return [x, y];
}

export function distance(p1: { x: number; y: number }, p2: { x: number; y: number }) {
    return Math.hypot(p2.x - p1.x, p2.y - p1.y);
}
