import WebFont from 'webfontloader';
import { App } from './App';
import './i18n';
// import './sandbox/gql';

const WebFontConfig = {
    custom: {
        families: ['Roboto-Regular', 'Roboto-Bold'],
        urls: ['/assets/fonts/fonts.css']
    }
};

WebFont.load(WebFontConfig);

new App();
