import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionMarkIcon } from '../../../assets/icons';
import { Howtoplay } from '../Modals/ModalHowtoplay';
import { DialogComponent } from '../other/Dialog';

export default function HowToPlayButton() {
    const state = useState(false);
    const [_isHelpOpen, setHelpOpen] = state;
    const { t } = useTranslation();

    return (
        <>
            <button
                onClick={setHelpOpen.bind(null, true)}
                className="h-6 px-2 mx-1 flex text-sm text-yellow-950 bg-yellow-600 border-orange-700 rounded-full items-center"
            >
                <QuestionMarkIcon className="w-3 h-3" />
                <div className="hidden sm:block ml-1">{t('header.howtoplay')}</div>
            </button>

            <DialogComponent state={state}>
                <Howtoplay />
            </DialogComponent>
        </>
    );
}
