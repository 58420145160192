import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { THistoryRoundMultipliersEntry } from '../../../Shared/MessageTypes';
import { getGameHistory } from '../../../Shared/services/queries';
import { ArrowDown } from '../../assets/icons/ArrowDown';
import { HistoryIcon } from '../../assets/icons/HistoryIcon';
import { valueToScaleClamped } from '../utils/utils';
import ModalRoundInfo from './Modals/ModalRoundInfo';
import { appContext } from './contexts';
import { DialogComponent } from './other/Dialog';
import { MultiplierBadge } from './other/MultiplierBadge';
import { useEventify } from './utils';

export default function () {
    const app = useContext(appContext);
    const [multipliersHistory, setMultipliersHistory] = useState<THistoryRoundMultipliersEntry[]>([]);
    const { games } = getGameHistory();
    const [openHistory, setOpenHistory] = useState(false);

    const handleOpenHistory = () => {
        setOpenHistory(!openHistory);
    };

    useEffect(() => {
        if (games.length > 0) {
            // initial fetch
            app.addInitialMultiplierHistory(games);
        }
    }, [games]);

    useEventify((eventify) => {
        eventify.listenTo(app.state, 'change:multipliersHistory', () => {
            return setMultipliersHistory(app.state.multipliersHistory);
        })();
    });

    return (
        <div className="flex flex-row gap-2 pl-2.5 pr-1 w-full justify-between items-center relative py-1 lg:py-2">
            <div className="flex flex-row gap-1  w-full justify-between overflow-hidden ">
                {multipliersHistory && (
                    <div className="flex gap-1 w-full">
                        {(multipliersHistory || []).map(({ id, multiplier }, i) => (
                            <MultiplierHistoryEntry key={id} entry={{ id, multiplier }} index={i} />
                        ))}
                    </div>
                )}
            </div>
            <div className="history-panel-shadow" />
            <button
                className={`
                    "relative z-[20] w-fit h-fit flex items-center gap-1 px-[6px] py-[2px] bg-modal-gray-light rounded-full transition-all duration-300 ease-in-out hover:bg-[#545b62] hover:text-button-orange-dark
                    ${openHistory ? '!bg-[#545b62] text-button-orange-dark' : ''}
                    `}
                onClick={handleOpenHistory}
                onKeyDown={handleOpenHistory}
            >
                <HistoryIcon />
                <div className={openHistory ? '[&>*]:rotate-180' : ''}>
                    <ArrowDown className="w-4 h-4 -mx-[2px]" />
                </div>
            </button>
            <div
                className={`absolute top-0.5 lg:top-1 left-0 w-full h-full z-[15]
                transition-all duration-300 ease-in-out
                ${
                    openHistory
                        ? 'block opacity-100 translate-y-0 scale-y-100 '
                        : 'opacity-0 pointer-events-none translate-y-[-100%] scale-y-0 '
                }`}
            >
                <RoundHistory multipliersHistory={multipliersHistory} />
            </div>
        </div>
    );
}

interface MultiplierHistoryEntryProps {
    entry: THistoryRoundMultipliersEntry;
    index: number;
}

export const MultiplierHistoryEntry: React.FC<MultiplierHistoryEntryProps> = ({ entry, index = 1 }) => {
    const [modalOpenedState, setModalOpenedState] = useState<boolean>(false);
    function toggleState() {
        setModalOpenedState(!modalOpenedState);
    }

    const opacity = (1 - valueToScaleClamped(index, 0, 30)) * 255;

    return (
        <div className="flex items-center">
            <DialogComponent state={[modalOpenedState, setModalOpenedState]}>
                <ModalRoundInfo initialData={{ id: entry.id, multiplier: entry.multiplier }} />
            </DialogComponent>

            <MultiplierBadge
                data-round-id={entry.id}
                multiplier={entry.multiplier}
                opacity={opacity}
                onClick={toggleState}
            />
        </div>
    );
};

interface MultipliersHistoryProps {
    multipliersHistory: THistoryRoundMultipliersEntry[];
}

export const RoundHistory: React.FC<MultipliersHistoryProps> = ({ multipliersHistory }) => {
    const latestMultipliersHistory = (multipliersHistory || []).slice(0, 60);

    return (
        <div className="flex flex-col w-full justify-between ">
            <div className="text-sm font-medium  modal-header bg-modal-gray-light px-2.5 pt-1 pb-1 rounded-t-xl leading-5">
                {t('roundHistory')}
            </div>
            {latestMultipliersHistory.length > 0 && (
                <div className="flex flex-wrap gap-1 w-full panel-bg rounded-b-xl px-2.5 py-1.5">
                    {latestMultipliersHistory.map(({ id, multiplier }, i) => (
                        <MultiplierHistoryEntry key={id} entry={{ id, multiplier }} index={1} />
                    ))}
                </div>
            )}
        </div>
    );
};
