import moment, { Moment } from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'react-scrollbars-custom';
import { IBetInfo } from '../../../../Shared/Types';
import { MessageIcon, ShieldIcon } from '../../../assets/icons';
import SuperMap from '../../utils/SuperMap';
import ModalRoundInfo from '../Modals/ModalRoundInfo';
import { appContext } from '../contexts';
import { DialogComponent } from '../other/Dialog';
import { MultiplierBadge } from '../other/MultiplierBadge';
import { useEventify } from '../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ServerBetType = any;

export type MyBetType = {
    id: string;
    userId: string;
    roundId: string;
    date: Moment;
    amount: number;
    multiplier: number;
    bet: number;
    win: number;
    username: string;
    currency: string;
};

export const mapServerBetToIBetInfo = (bet: ServerBetType): MyBetType => ({
    id: bet.id,
    userId: bet.playerId || '',
    roundId: bet.gameRoundId,
    date: moment(bet.created),
    amount: bet.finalAmount,

    // below defaults are needed since server returns null instead of 0
    multiplier: bet.multiplier || 0,
    bet: bet.finalAmount || 0,
    win: bet.winFinalAmount || 0,
    username: bet.playerId || '',
    currency: bet.currency || ''
});

const sortBets = (a: MyBetType, b: MyBetType) => moment(b.date).diff(moment(a.date));

export function MyBetsContainer() {
    const { t } = useTranslation();
    const app = useContext(appContext);

    const [myBets, setMyBets] = useState<ServerBetType[]>([...app.myBets.values()].sort(sortBets));

    useEventify((eventify) => {
        eventify.listenTo(app.myBets, SuperMap.Events.all, (data) => {
            setMyBets([...app.myBets.values()].sort(sortBets));
        });
    }, []);

    return (
        <>
            <div className="flex flex-row flex-grow-1 px-2.5">
                <div className="w-1/5">{t('sidebar.date')}</div>
                <div className="w-2/5 flex-1 text-center">{t('sidebar.bet')}</div>
                <div className="w-2/5 flex-1 text-right pr-11">{t('sidebar.cashOut')}</div>
            </div>
            <Scrollbar className="flex flex-row h-full px-1.5">
                <div className="flex flex-col w-full gap-0.5 text-sm">
                    {myBets.map((betInfo) => {
                        return <BetItem key={betInfo.id} data={betInfo} />;
                    })}
                </div>
            </Scrollbar>
        </>
    );
}

function BetItem({ data }: { data: IBetInfo }) {
    const modalOpened = useState(false);
    function toggleState() {
        modalOpened[1](!modalOpened[0]);
    }

    return (
        <div className="flex flex-row border border-transparent rounded-md panel-bg-alt p-0.5">
            <DialogComponent state={modalOpened} key={data.id}>
                <ModalRoundInfo initialData={{ id: data.roundId, multiplier: data.multiplier }} />
            </DialogComponent>

            <div className="flex items-center gap-1 w-1/5">
                <div className="flex flex-shrink">{moment(data.date).format('HH:mm')}</div>
            </div>
            <div className="flex items-center w-2/5 gap-1">
                <div className="flex justify-end w-2/3">
                    {data.currency} {Number(data.bet || 0).toFixed(2)}
                </div>
                <div className="flex justify-start w-1/3">
                    <div className={data.multiplier == 0 ? ' hidden' : ''}>
                        <MultiplierBadge multiplier={data.multiplier} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row w-2/5 items-center justify-end gap-1 pr-1">
                <div>
                    {data.currency} {Number(data.win || 0).toFixed(2)}
                </div>
                <div className="cursor-pointer flex gap-1" data-round-id={data.roundId} onClick={toggleState}>
                    <ShieldIcon className=" fill-green-600 h-4 w-4" />
                    <MessageIcon className="fill-white h-4 w-4" />
                </div>
                {/* <div>💬</div> */}
            </div>
        </div>
    );
}
