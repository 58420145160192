import { Howl } from 'howler';
import { deferrify } from '../../Shared/Eventify';
import { AddPropertiesToWindow } from './Components/utils';
import { ResourceType } from './Visualisation/Models/AssetsModel';
import { AUDIO } from './Visualisation/assets';
import { config } from './config';

export default class AudioManager {
    private musicVolume = 1;
    private soundVolume = 1;
    get targetMusicVolume() {
        return this.pageVisible ? this.musicVolume : 0;
    }
    get targetSoundVolume() {
        return this.pageVisible ? this.soundVolume : 0;
    }
    MUSIC: Howl[] = [];
    SOUND_FX: Howl[] = [];
    audioList: { [key: string]: Howl } = {};

    private pageVisible = false;
    constructor() {
        AddPropertiesToWindow({ Howl: Howl });

        this.pageVisible = !document.hidden;

        addEventListener('focus', () => {
            this.visibilityChange(true);
        });

        addEventListener('blur', () => {
            this.visibilityChange(false);
        });

        document.addEventListener('visibilitychange', () => {
            this.visibilityChange(!document.hidden);
        });
    }

    visibilityChange(isVisible: boolean) {
        this.pageVisible = isVisible;
        this.setMusicVolume(this.musicVolume);
        this.setSoundVolume(this.soundVolume);
    }

    create = () => {
        document.body.addEventListener('pointerdown', this.init, {
            once: true
        });
    };

    private init = () => {
        for (const m of Object.values(this.audioList)) {
            m.volume(1);
        }
    };

    loadAudio = (type: ResourceType) => {
        const promisesArray = AUDIO[type]!.map((audio) => {
            const deferred = deferrify<Howl>();
            const howl = new Howl({
                src: [`${config.assetsLocation}${AUDIO.defaultPath}${audio.path}`],
                loop: !!audio.loop,
                autoplay: audio.autoplay,

                onload: () => {
                    howl.volume(audio.type === 'MUSIC' ? this.targetMusicVolume : this.targetSoundVolume);
                    deferred.resolve(howl);
                }
            });

            if (audio.type === 'MUSIC') {
                this.MUSIC.push(howl);
            }

            if (audio.type === 'FX') {
                this.SOUND_FX.push(howl);
            }

            this.audioList[audio.key] = howl;
            return deferred.promise;
        });

        return Promise.all(promisesArray);
    };

    setMusicVolume = (volume: number) => {
        this.musicVolume = volume;
        for (const o of this.MUSIC) {
            o.volume(this.targetMusicVolume);
        }
    };

    setSoundVolume = (volume: number) => {
        this.soundVolume = volume;
        for (const o of this.SOUND_FX) {
            o.volume(this.targetSoundVolume);
        }
    };
}

// window.addEventListener('focus', () => {
//     if (Global_Vars.are_sound_fx_on && Global_Vars.is_music_on) {
//         Howler.mute(false);
//     }
// });

// document.addEventListener('visibilitychange', () => {
//     if (document.hidden) {
//         Howler.mute(true);
//     } else {
//         if (Global_Vars.are_sound_fx_on && Global_Vars.is_music_on) {
//             Howler.mute(false);
//         }
//     }
// });
