import { Disclosure, Popover, Transition } from '@headlessui/react';
import { Fragment, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimationIcon, AvatarIcon, GameLimitsIcon, MusicIcon, RulesIcon, SoundIcon } from '../../../assets/icons';
import { settings } from '../../utils/Settings';
import { UiOption } from '../MenuComponents';
import ModalAvatarChoose from '../Modals/ModalAvatarChoose';
import { ModalGameLimits } from '../Modals/ModalGameLimits';
import ModalGamerules from '../Modals/ModalGamerules';
import { appContext } from '../contexts';
import Avatar from '../other/Avatar';
import { DialogComponent } from '../other/Dialog';
import Username from '../other/Username';
import { useEventify } from '../utils';

export default function () {
    const app = useContext(appContext);

    const stateWhatIsPF = useState(false);
    const stateModalAvatar = useState(false);
    const stateModalGameLimits = useState(false);

    const { t } = useTranslation();

    const [username, setUsername] = useState(app.state.name);
    useEventify((eventify) => eventify.listenTo(app.state, 'name', setUsername), []);

    return (
        <Popover __demoMode={false} className="relative">
            <DialogComponent state={stateModalAvatar}>
                <ModalAvatarChoose />
            </DialogComponent>

            <DialogComponent state={stateWhatIsPF}>
                <ModalGamerules />
            </DialogComponent>

            <Popover.Button className="w-8 h-8 flex items-center text-xl justify-center bg-white bg-opacity-0 font-medium text-white hover:bg-opacity-20 focus:outline-none focus-visible:ring-white pb-1">
                ☰
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Popover.Panel className="absolute right-0 w-56 origin-top-right divide-y divide-zinc-700  shadow-md shad shadow-black focus:outline-none [&>*:first-child]:rounded-t-xl [&>*:last-child]:rounded-b-xl">
                    <Disclosure as="div" className="menu-row !bg-zinc-700 !cursor-default !px-1 lg:px-2">
                        <div className="flex flex-row items-center gap-4">
                            <div className="flex items-center gap-2">
                                <Avatar className="!w-8" data-avatarid={`${app.avatarId}`} />
                                <Username className="font-bold" name={username} data-userid={'123'} />
                            </div>
                            <button
                                className="rounded-full break-words text-xs panel-bg panel-bg-alt p-1 px-2 text-zinc-400 flex items-center"
                                onClick={stateModalAvatar[1].bind(null, true)}
                            >
                                <AvatarIcon className="h-6 w-6 fill-zinc-400" />
                                {t('menu.changeAvatar')}
                            </button>
                        </div>
                    </Disclosure>

                    <UiOption target={settings} name="musicEnabled" icon={<MusicIcon />} />
                    <UiOption target={settings} name="soundsEnabled" icon={<SoundIcon />} />
                    <UiOption
                        target={settings}
                        name="animationEnabled"
                        icon={<AnimationIcon className="w-8 -ml-2" />}
                    />

                    <Disclosure as="div" className="menu-row" onClick={stateWhatIsPF[1].bind(null, true)}>
                        <div className="flex flex-row items-center">
                            <div className="w-4 mx-1 mr-2">
                                <RulesIcon className="fill-white" />
                            </div>
                            <div>{t('menu.gameRules')}</div>
                        </div>
                        <DialogComponent state={stateWhatIsPF}>
                            <ModalGamerules />
                        </DialogComponent>
                    </Disclosure>
                    <Disclosure as="div" className="menu-row" onClick={stateModalGameLimits[1].bind(null, true)}>
                        <div className="flex flex-row items-center">
                            <div className="w-4 mx-1 mr-2">
                                <GameLimitsIcon className="fill-white" />
                            </div>
                            <div>{t('menu.gameLimits')}</div>
                        </div>
                        <ModalGameLimits state={stateModalGameLimits} />
                    </Disclosure>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}
