import { Dialog } from '@headlessui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../other/Button';
import { DialogContext } from '../other/Dialog';

export const Howtoplay: React.FC<{}> = () => {
    const { t } = useTranslation();
    const { close } = useContext(DialogContext);

    const helpSteps = [
        {
            name: t('modalHow.step1.name'),
            description: t('modalHow.step1.description')
        },
        {
            name: t('modalHow.step2.name'),
            description: t('modalHow.step2.description')
        },
        {
            name: t('modalHow.step3.name'),
            description: t('modalHow.step3.description')
        }
    ];
    return (
        <>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header">
                {t('modalHow.title')}
            </Dialog.Title>
            <iframe
                className="bg-black w-full h-full aspect-video"
                src="https://www.youtube.com/embed/D0UnqGm_miA?si=cqrVtxGl1UoSGuc0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>

            <div className="relative grid gap-3 grid-cols-1">
                {helpSteps.map((item, index) => (
                    <div key={item.name} className=" flex items-center rounded-lg p-2">
                        <div className="flex h-10 w-10 shrink-0 items-center justify-center modal-text-a sm:h-12 sm:w-12">
                            {(1 + index).toLocaleString('en-US', {
                                minimumIntegerDigits: 2
                            })}
                        </div>
                        <div className="ml-4">
                            <p className="text-sm font-medium text-modal-a">{item.name}</p>
                            <p className="text-sm modal-text-b">{item.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="bottom-bar">
                <Button onClick={close}>{t('modalHow.button')}</Button>
            </div>
        </>
    );
};
